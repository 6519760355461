// Do not use styled-components global style due to https://github.com/styled-components/styled-components/issues/2227
import { memo } from 'react';
import { SHOULD_USE_CDN, CDN_URL } from './utils/constants'

const FONT_URL_PREFIX = SHOULD_USE_CDN ? CDN_URL : '';

const GlobalStyle = () => (
  <style jsx global>
    {`
      @font-face {
        font-family: 'Atlas Grotesk';
        src: url('${FONT_URL_PREFIX}/visuals/fonts/AtlasGrotesk-Regular.ttf') format('truetype');
        font-style: normal;
        font-weight: 400;
        font-display: swap;
      }

      @font-face {
        font-family: 'Atlas Grotesk';
        src: url('${FONT_URL_PREFIX}/visuals/fonts/AtlasGrotesk-RegularItalic.ttf')
          format('truetype');
        font-style: italic;
        font-display: swap;
      }

      @font-face {
        font-family: 'Atlas Grotesk';
        src: url('${FONT_URL_PREFIX}/visuals/fonts/AtlasGrotesk-Medium.ttf') format('truetype');
        font-weight: bold;
        font-display: swap;
      }

      @font-face {
        font-family: 'Atlas Grotesk';
        src: url('${FONT_URL_PREFIX}/visuals/fonts/AtlasGrotesk-Black.ttf') format('truetype');
        font-weight: 900;
        font-display: swap;
      }

      html {
        scroll-behavior: smooth;
      }

      // https://github.com/w3c/csswg-drafts/issues/865
      body {
        width: 100%;
        -webkit-print-color-adjust: exact;
        overflow: auto;
        scroll-behavior: smooth;
        margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Atlas Grotesk',
          'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
          'Droid Sans', 'Helvetica Neue', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #333;
        font-size: 15px;
        line-height: 1.428;
        background: #f5f5f5;
      }

      iframe,
      video {
        max-width: 100%;
      }

      @media print {
        body {
          font-size: 12px;
        }
      }
      table {
        width: 100%;
        border-collapse: collapse;
      }
      @media screen and (max-width: 770px) {
        table td p {
          margin: 0;
        }
      }
      table.striped tr:nth-child(even) {
        background-color: #f2f2f2;
      }
      table.striped tr th {
        background-color: #e05d2d;
        font-weight: bold;
        color: white;
        border: none;
        text-align: left;
      }
      th,
      td {
        padding: 2px 5px;
      }

      h1,
      h2,
      h3 {
        font-weight: 900;
      }
    
      * {
        box-sizing: border-box;
      }

      section svg, section img {
        height: auto;
      }

      svg {
        height: auto;
        clear: both;
        display: block;
      }

      code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
          monospace;
      }

      a {
        text-decoration: none;
        color: #fa6831;
        transition: all 500ms;
      }
      a.active {
        font-weight: bold;
      }

      ul,
      ol {
        list-style-position: outside;
        margin-left: 0.7em;
        padding-left: 1em;
        margin-bottom: 0.3em;
        margin-top: 0.3em;
      }

      ol li,
      ul li {
        margin-bottom: 0.3em;
      }

      ul.slick-dots.slick-thumb {
        display: flex !important;
        position: relative;
        bottom: 0;
      }

      .slick-slide {
        position: relative;
      }

      .slick-slide img {
        display: inline-block;
      }

      .slick-dots.slick-thumb li {
        width: auto;
        height: auto;
        padding: 1px;
        margin: 0 3px 0 0;
        border: 1px solid rgba(0, 0, 0, 0.1);
      }

      .slick-dots.slick-thumb li.slick-active {
        border: 2px solid #aaa;
        padding: 0;
      }

      .slick-prev {
        left: 10px;
        top: calc(50% - 100px);
        z-index: 40;
      }
      .slick-next {
        right: 10px;
        top: calc(50% - 100px);
        z-index: 40;
      }

      .slick-list {
        border: 1px solid rgba(0, 0, 0, 0.1);
      }
      .slick-dots {
        top: 5px;
      }

      .key {
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 0.1em 0.5em;
        margin: 0 0.2em;
        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
        background-color: #f7f7f7;
      }

      // Tag input
      .react-tags__suggestions {
        z-index: 9999;
      }

      // Tinymce
      .tox.tox-tinymce.tox-tinymce-inline {
        z-index: 999;
      }

      // FA
      .fa-beat {
        -webkit-animation: fa-beat 1s infinite linear;
        animation: fa-beat 1s infinite linear;
      }
      @-webkit-keyframes fa-beat {
        0% {
          -webkit-transform: scale(0.9);
          transform: scale(0.9);
        }
        50% {
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
        }
        100% {
          -webkit-transform: scale(0.9);
          transform: scale(0.9);
        }
      }
      @keyframes fa-beat {
        0% {
          -webkit-transform: scale(0.9);
          transform: scale(0.9);
        }
        50% {
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
        }
        100% {
          -webkit-transform: scale(0.9);
          transform: scale(0.9);
        }
      }

      /**
   * prism.js default theme for JavaScript, CSS and HTML
   * Based on dabblet (http://dabblet.com)
   * @author Lea Verou
   */
      code[class*='language-'],
      pre[class*='language-'] {
        color: black;
        background: none;
        text-shadow: 0 1px white;
        font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
        font-size: 0.9em;
        text-align: left;
        white-space: pre;
        word-spacing: normal;
        word-break: normal;
        word-wrap: normal;
        line-height: 1.5;
        -moz-tab-size: 4;
        tab-size: 4;
        -webkit-hyphens: none;
        -ms-hyphens: none;
        hyphens: none;
      }
      .codesample-inline {
        font-size: 10pt;
        font-family: 'Courier New';
        color: rgb(36, 41, 46);
        background-color: #eee;
        font-weight: 400;
        font-style: normal;
        font-variant: normal;
        text-decoration: none;
        vertical-align: baseline;
        white-space: pre-wrap;
        padding: 2px 4px;
      }
      pre[class*='language-']::-moz-selection,
      pre[class*='language-'] ::-moz-selection,
      code[class*='language-']::-moz-selection,
      code[class*='language-'] ::-moz-selection {
        text-shadow: none;
        background: #b3d4fc;
      }
      pre[class*='language-']::selection,
      pre[class*='language-'] ::selection,
      code[class*='language-']::selection,
      code[class*='language-'] ::selection {
        text-shadow: none;
        background: #b3d4fc;
      }
      @media print {
        code[class*='language-'],
        pre[class*='language-'] {
          text-shadow: none;
        }
      }
      /* Code blocks */
      pre[class*='language-'] {
        padding: 1em;
        margin: 0.5em 0;
        overflow: auto;
      }
      :not(pre) > code[class*='language-'],
      pre[class*='language-'] {
        background: #f5f2f0;
      }
      /* Inline code */
      :not(pre) > code[class*='language-'] {
        padding: 0.1em;
        border-radius: 0.3em;
        white-space: normal;
      }
      .slick-arrow {
        display: none !important;
      }

      .MuiTableCell-body {
        min-width: 0 !important;
      }
    `}
  </style>
);

export default memo(GlobalStyle, () => true);
