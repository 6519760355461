/**
 * @file Global state provider
 * @todo logout
 */
import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import persistentState, { PERSISTENT_STATE_KEYS } from '../../utils/persistentState';
// import fetch from '../../../data/restApi/fetchApi';
import { useCheckRole } from '../../utils/hooks';
import { ROLES } from '../../utils/constants';
import { isBlank } from '../../utils/helpers';

const userCookie = persistentState.cookie.get({
  key: PERSISTENT_STATE_KEYS.user,
});

const initialState = {
  currentUser:
    (process.browser && userCookie
    ) || {},
  roles: userCookie ? userCookie.capabilities : [],
  isPreview:
    (process.browser && sessionStorage.getItem('prusuki_preview_mode')) ||
    false,
  isAdmin: false,
  isLoggedIn: false,
  password: '',
};

export const GlobalContext = React.createContext(initialState);
export const DispatchContext = React.createContext();

function reducer(state, action) {
  switch (action.type) {
    case 'setPreview':
      const payload = action.payload;
      process.browser &&
        sessionStorage.setItem('prusuki_preview_mode', payload);
      return { ...state, isPreview: payload };
    case 'setUser':
      persistentState.cookie.set({
        key: PERSISTENT_STATE_KEYS.user,
        value: JSON.stringify(action.payload.currentUser),
      });
      return {
        ...state,
        isAdmin: action.payload.isAdmin,
        isLoggedIn: !!action.payload.currentUser.userid,
        currentUser: action.payload.currentUser,
        roles: action.payload.roles,
      };
    case 'setPassword':
      return { ...state, password: action.payload };
    default:
      throw new Error('Unknown action type.');
  }
}

const GlobalProvider = ({ children, userData }) => {

  const [state, dispatch] = useReducer(reducer, initialState);
  const roles = userData?.capabilities || state?.currentUser?.capabilities || [];
  const { isInAnyRole } = useCheckRole(roles);

  // const { data: userData, isLoading } = fetch.user.withSWR();

  useEffect(() => {
    if (!process.browser) return;

    // sessionStorage.setItem('prusuki_current_user', JSON.stringify(userData));
    persistentState.cookie.set({
      key: PERSISTENT_STATE_KEYS.user,
      value: JSON.stringify(userData),
    });

    if (
      // !isLoading &&
      !isBlank(userData) &&
      userData?.capabilities
    ) {
      // sessionStorage.removeItem('jwt_token');
      sessionStorage.removeItem('prusuki_preview_mode');
      // sessionStorage.removeItem('prusuki_current_user');
      persistentState.cookie.delete({
        key: PERSISTENT_STATE_KEYS.user,
      });
      persistentState.cookie.delete({
        key: PERSISTENT_STATE_KEYS.jwt,
      });
      // return;
    }

    if (!userData?.capabilities) return;

    const isAdmin = isInAnyRole([ROLES.EMPLOYEE, ROLES.AUTHOR, ROLES.ADMIN]);

    // sessionStorage.setItem('jwt_token', userData.token.token);
    persistentState.cookie.set({
      key: PERSISTENT_STATE_KEYS.jwt,
      value: userData.token.token,
    });

    dispatch({
      type: 'setUser',
      payload: {
        currentUser: userData,
        roles: userData?.capabilities || [],
        isAdmin,
      },
    });
  }, [userData]);

  React.useEffect(() => {
    if (!process.browser) return;

    const hash = window.location.hash;
    if (hash.startsWith('#text')) {
      const newHash = hash.replace('#text', '#:~:text=');
      setTimeout(() => {
        window.location.hash = newHash;
      }, 1000);
    }
  }, []);

  const isMedical = isInAnyRole([ROLES.MEDIC, ROLES.MEDIC_SERVICE_TECHNICIAN]);

  const theme = {
    colors: {
      main: isMedical ? '#00a8a4' : '#e05d2d',
      mainLight: isMedical ? '#83d5d3' : '#fa8f70',
      mainDark: isMedical ? '#007976' : '#ca4d1f',
      smoggishGrey: 'rgb(245, 245, 245)',
      lightGrey: '#e0e0e0',
      grey: '#B3B3B3',
      text: '#000000',
    },
    breakpoints: {
      mobile: '576px',
    },
  };

  return (
    <DispatchContext.Provider value={{ dispatch }}>
      <GlobalContext.Provider value={{ ...state, currentUser: userData }}>
        <ThemeProvider theme={theme}>
          {children}
        </ThemeProvider>
      </GlobalContext.Provider>
    </DispatchContext.Provider>
  );
};

GlobalProvider.propTypes = {
  value: PropTypes.object,
  children: PropTypes.object,
};

export default GlobalProvider;
